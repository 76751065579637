import React from "react";
import styled, { keyframes } from "styled-components";

const shimmer = keyframes`
 {
   0% {
     background-position:0% 50%
   }
   50% {
     background-position:100% 50%
   }
   100% {
     background-position:0% 50%
   }
}
`;

const Container = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(270deg, #106ca7, #6667a5);
  background-size: 400% 400%;
  animation: ${shimmer} 20s ease infinite;

  * {
    transition: 1.2s ease;
  }
`;

const Canvas = styled("div")`
  padding: 0;
  margin: 0;
  width: 300px;
  height: 300px;
  position: relative;
`;

const Text = styled("button")`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  max-width: 300px;
  color: white;
  font-family: Helvetica Neue, Arial, sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  position: absolute;
  cursor: pointer;
`;

const message = [
  "SL",
  "W",
  "loves",
  "TAC",
  "Everything will be",
  "O",
  "K",
  "."
];

const before = {
  SL: { top: "80px", left: "80px" },
  O: { top: "80px", left: "108px" },
  W: { top: "80px", left: "126px" },
  TAC: { top: "80px", left: "150px" },
  K: { top: "80px", left: "195px" },
  loves: { top: "80px", left: "126px", opacity: 0 },
  "Everything will be": { top: "120px", left: "5px", opacity: 0 },
  ".": { top: "120px", left: "235px", opacity: 0 }
};

const panda = { background: "white", color: "black" };

const after = {
  SL: { top: "80px", left: "67px", ...panda },
  W: { top: "80px", left: "95px", ...panda },
  loves: { top: "80px", left: "126px", color: "#ff5566" },
  TAC: { top: "80px", left: "191px", ...panda },
  "Everything will be": { top: "120px", left: "5px" },
  O: { top: "120px", left: "199px", ...panda },
  K: { top: "120px", left: "217px", ...panda },
  ".": { top: "120px", left: "235px" }
};

class Message extends React.Component {
  state = { toggle: true };

  changeActive = () => {
    const { toggle } = this.state;
    this.setState({ toggle: !toggle });
  };

  render() {
    const { toggle } = this.state;
    let active = toggle ? before : after;
    // active = after;

    return (
      <Canvas>
        {message.map(part => {
          return (
            <Text onClick={this.changeActive} key={part} style={active[part]}>
              {part}
            </Text>
          );
        })}
      </Canvas>
    );
  }
}

const App = () => (
  <Container>
    <Message />
  </Container>
);

export default App;
